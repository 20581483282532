// src/api/axiosInstance.js

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Base URL for all requests
  headers: {
    "Content-Type": "application/json" // Default content type
  }
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Attach token from localStorage dynamically before each request
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error here
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response, // Pass successful response
  (error) => {
    // Handle global error responses
    if (error.response) {
      // Log or handle specific errors (e.g., 401 Unauthorized)
      if (error.response.status === 401) {
        console.error("Unauthorized access - perhaps redirect to login.");
        // Optional: Redirect to login page or remove token
        localStorage.removeItem("token");
      }
    }
    return Promise.reject(error); // Pass error to catch blocks in API functions
  }
);

export default axiosInstance;
