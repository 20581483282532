import React, { useState, useContext, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { MenuItem, Select, FormControl, InputLabel, Button, Box, TextField, CircularProgress } from "@mui/material";
import { fetchDataFromApi } from "../../utils/api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { MyContext } from "../../App";
import { useNavigate } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// Breadcrumb styling
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const Sales = () => {
  const [salesData, setSalesData] = useState([]);
  const [view, setView] = useState("yearly");
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(MyContext);
  const history = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    // Fetch sales data from the backend
    fetchDataFromApi("/api/orders/get/active")
      .then((orders) => {
        const processedData = orders.map((order) => {
          const subtotal = order.subtotal || order.products.reduce((sum, product) => sum + product.price * product.quantity, 0);
          const amount = order.amount;
          const discount = subtotal - amount;

          // Ensure date is treated consistently in UTC
          const utcDate = new Date(order.date);
          const dateString = utcDate.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
          const timeString = utcDate.toISOString().split("T")[1].slice(0, 8);
          const timeParts = timeString.split(':');
          const hours = parseInt(timeParts[0], 10);
          const suffix = hours >= 12 ? 'PM' : 'AM';
          const adjustedHour = hours % 12 || 12;
          const dateTime = `${adjustedHour}:${timeParts[1]} ${suffix}`;
          return {
            date: dateString,  // Store in UTC format for consistent comparisons
            totalSales: order.products.reduce((sum, product) => sum + product.price * product.quantity, 0),
            subtotal,
            amount,
            discount,
            time: dateTime
          };
        });
        processedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setSalesData(processedData);
        setIsLoading(false);

        // Calculate overall sales count, amount, and discount
        const overallSalesCount = processedData.length;
        const overallAmount = processedData.reduce((sum, item) => sum + parseFloat(item.amount), 0).toFixed(2);
        const overallDiscount = processedData.reduce((sum, item) => sum + parseFloat(item.discount), 0).toFixed(2);

        console.log("Overall Sales Count:", overallSalesCount);
        console.log("Overall Amount:", overallAmount);
        console.log("Overall Discount:", overallDiscount);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setIsLoading(false);
      });
  }, []);

  const handleViewChange = (event) => {
    setView(event.target.value);
    setSelectedDate("");
    setStartDate("");
    setEndDate("");
    setStartYear("");
    setEndYear("");
  };

  const getFilteredData = () => {
    const today = new Date().toISOString().split("T")[0]; // Current date in "YYYY-MM-DD" format

    if (view === "daily" && selectedDate) {
      // Filter for exact date match
      return salesData.filter((item) => item.date === selectedDate);
    } else if (view === "weekly" && startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return salesData.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    } else if (view === "monthly" && startDate && endDate) {
      const startMonth = startDate.slice(0, 7); // "YYYY-MM"
      const endMonth = endDate.slice(0, 7);
      return salesData.filter((item) => {
        const itemMonth = item.date.slice(0, 7);
        return itemMonth >= startMonth && itemMonth <= endMonth;
      });
    } else if (view === "yearly" && startYear && endYear) {
      return salesData.filter((item) => {
        const year = parseInt(item.date.slice(0, 4));
        return year >= parseInt(startYear) && year <= parseInt(endYear);
      });
    }

    return salesData;
  };

  const filteredData = getFilteredData();

  const chartData = {
    labels: filteredData.map((entry) =>
      view === "daily" ? entry.time : entry.date // Use time for daily view, date otherwise
    ),
    datasets: [
      {
        label: `Total Sales Amount (${view})`,
        data: filteredData.map((entry) => entry.totalSales),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        tension: 0.2,
      },
    ],
  };
  

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: `Sales Report (${view.charAt(0).toUpperCase() + view.slice(1)})` },
    },
    scales: {
      x: {
        title: { display: true, text: view === "daily" ? "Time" : "Date" },
        ticks: {
          maxTicksLimit: view === "daily" ? 10 : view === "monthly" ? 12 : view === "weekly" ? 52 : 5,
        },
      },
      y: { title: { display: true, text: "Sales (in Rs)" }, beginAtZero: true },
    },
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.setTextColor(40);
    doc.text("Shop Me", 14, 22);
    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.text(`Sales Report - ${view.charAt(0).toUpperCase() + view.slice(1)}`, 14, 30);

    doc.autoTable({
      head: [["S.No", "Date", "Subtotal (in Rs)", "Discount (%)", "Discount (in Rs)", "Total Sales (in Rs)"]],
      body: filteredData.map((row, index) => {
        const discountPercentage = ((row.discount / row.subtotal) * 100).toFixed(2);
        return [
          index + 1,
          row.date,
          `${parseFloat(row.subtotal).toFixed(2)} Rs`,
          `${discountPercentage} %`,
          `${parseFloat(row.discount).toFixed(2)} Rs`,
          `${parseFloat(row.amount).toFixed(2)} Rs`,
        ];
      }),
      startY: 40,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 4, lineColor: [44, 62, 80], lineWidth: 0.1 },
      headStyles: { fillColor: [75, 192, 192], textColor: 255, fontStyle: "bold", halign: "center" },
      columnStyles: { 0: { halign: "center" }, 1: { halign: "center" }, 2: { halign: "center" }, 3: { halign: "center" }, 4: { halign: "center" } },
    });

    // Add overall sales count, amount, and discount to the PDF
    const overallSalesCount = filteredData.length;
    const overallAmount = filteredData.reduce((sum, item) => sum + parseFloat(item.amount), 0).toFixed(2);
    const overallDiscount = filteredData.reduce((sum, item) => sum + parseFloat(item.discount), 0).toFixed(2);

    doc.autoTable({
      head: [["Overall Sales Count", "Overall Amount (in Rs)", "Overall Discount (in Rs)"]],
      body: [[overallSalesCount, overallAmount, overallDiscount]],
      startY: doc.autoTable.previous.finalY + 10,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 4, lineColor: [44, 62, 80], lineWidth: 0.1 },
      headStyles: { fillColor: [75, 192, 192], textColor: 255, fontStyle: "bold", halign: "center" },
      columnStyles: { 0: { halign: "center" }, 1: { halign: "center" }, 2: { halign: "center" } },
    });

    doc.save(`sales_report_${view}.pdf`);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData.map((row, index) => ({
      "S.No": index + 1,
      "Date": row.date,
      "Subtotal (in Rs)": parseFloat(row.subtotal).toFixed(2),
      "Discount (%)": ((row.discount / row.subtotal) * 100).toFixed(2),
      "Discount (in Rs)": parseFloat(row.discount).toFixed(2),
      "Total Sales (in Rs)": parseFloat(row.amount).toFixed(2),
    })));

    // Customizing the worksheet
    const header = ["S.No", "Date", "Subtotal (in Rs)", "Discount (%)", "Discount (in Rs)", "Total Sales (in Rs)"];
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });

    // Adding styles
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1"; // First row
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { fgColor: { rgb: "4BC0C0" } },
        alignment: { horizontal: "center" },
      };
    }

    // Add overall sales count, amount, and discount to the Excel sheet
    const overallSalesCount = filteredData.length;
    const overallAmount = filteredData.reduce((sum, item) => sum + parseFloat(item.amount), 0).toFixed(2);
    const overallDiscount = filteredData.reduce((sum, item) => sum + parseFloat(item.discount), 0).toFixed(2);

    XLSX.utils.sheet_add_aoa(worksheet, [
      [],
      [`Overall Sales Count: ${overallSalesCount}`],
      [`Overall Amount: ${overallAmount} Rs`],
      [`Overall Discount: ${overallDiscount} Rs`]
    ], { origin: -1 });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Report");
    XLSX.writeFile(workbook, `sales_report_${view}.xlsx`);
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
          <h5 className="mb-0">Sales Report</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="Dashboard"
              icon={<HomeIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label="Sales"
              href="#"
              deleteIcon={<ExpandMoreIcon />}
            />
            <StyledBreadcrumb
              label="Sales Report"
              deleteIcon={<ExpandMoreIcon />}
            />
          </Breadcrumbs>
        </div>

        <div className="card p-4 mt-0">
          <FormControl variant="outlined" sx={{ minWidth: 120, marginBottom: 3 }}>
            <InputLabel>View</InputLabel>
            <Select value={view} onChange={handleViewChange} label="View">
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>

          <Box mt={2} display="flex" gap={2}>
            {view === "daily" && (
              <TextField
                label="Select Date"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            {(view === "weekly" || view === "monthly") && (
              <>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
            {view === "yearly" && (
              <>
                <TextField
                  label="Start Year"
                  type="number"
                  value={startYear}
                  onChange={(e) => setStartYear(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="End Year"
                  type="number"
                  value={endYear}
                  onChange={(e) => setEndYear(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </Box>

          {isLoading ? (
            <CircularProgress color="inherit" className="loader" />
          ) : (
            <Line data={chartData} options={options} />
          )}
        </div>

        <div className="card p-4 mt-0">
          <Button variant="contained" color="primary" onClick={downloadPDF} style={{ marginTop: "20px" }}>
            Download PDF Report
          </Button>
          <Button variant="contained" color="secondary" onClick={downloadExcel} style={{ marginTop: "20px", marginLeft: "10px" }}>
            Download Excel Report
          </Button>
        </div>
      </div>
    </>
  );
};

export default Sales;
