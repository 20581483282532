import React, { useEffect, useState, useContext } from "react";
import { fetchDataFromApi, deleteData } from "../../utils/api"; // API functions
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { FaPencilAlt } from "react-icons/fa"; // Edit Icon
import { MdDelete } from "react-icons/md"; // Delete Icon
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import Pagination from "@mui/material/Pagination";
import { MyContext } from "../../App"; // Assuming you're using a context
import Modal from 'react-modal'; // Import React Modal

// Breadcrumb styling
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const CouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const context = useContext(MyContext); // Assuming you're using context for alert handling

  useEffect(() => {
    fetchCoupons();
  }, [page]);

  const fetchCoupons = async () => {
    setLoading(true);
    try {
      const response = await fetchDataFromApi("/api/coupons");
      if (response.success) {
        setCoupons(response.coupons);
        setTotalPages(Math.ceil(response.coupons.length / itemsPerPage));
      }
    } catch (error) {
      console.error("Error fetching coupons:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle coupon deletion
  const handleDelete = async () => {
    if (couponToDelete) {
      try {
        await deleteData(`/api/coupons/${couponToDelete}`);
        fetchCoupons(); // Re-fetch coupons after deletion
        context.setAlertBox({
          open: true,
          error: false,
          msg: "Coupon Deleted Successfully!",
        });
      } catch (error) {
        console.error("Error deleting coupon:", error);
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to delete coupon.",
        });
      } finally {
        closeModal();
      }
    }
  };

  const openModal = (id) => {
    setCouponToDelete(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCouponToDelete(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedCoupons = coupons.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div className="right-content w-100">
      <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
        <h5 className="mb-0">Coupon List</h5>
        {/* Adding Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Dashboard"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb component="a" label="Coupons" />
        </Breadcrumbs>
      </div>

      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="card p-4 mt-2">
          {coupons.length === 0 ? (
            <p>No coupons available.</p>
          ) : (
            <>
              <table className="table table-bordered table-striped v-align">
                <thead className="thead-dark">
                  <tr>
                    <th>Coupon Code</th>
                    <th>Discount (%)</th>
                    <th>Expiry Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedCoupons.map((coupon) => (
                    <tr key={coupon._id}>
                      <td>{coupon.code}</td>
                      <td>{coupon.discount}%</td>
                      <td>{new Date(coupon.expiryDate).toLocaleDateString()}</td>
                      <td>
                        {/* Edit button */}
                        <div className="actions d-flex align-items-center">
                          <Button
                            className="success"
                            color="success"
                            onClick={() => navigate(`/coupon/edit/${coupon._id}`)}
                          >
                            <FaPencilAlt /> {/* Edit Icon */}
                          </Button>
                      
                          {/* Delete button */}
                          <Button
                            className="error ml-2"
                            color="error"
                            onClick={() => openModal(coupon._id)}
                          >
                            <MdDelete /> {/* Delete Icon */}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/* Fill empty rows to maintain table height */}
                  {Array.from({ length: itemsPerPage - paginatedCoupons.length }).map((_, index) => (
                    <tr key={`empty-${index}`}>
                      <td colSpan="4" style={{ height: '48px' }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            </>
          )}
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            textAlign: 'center'
          }
        }}
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this coupon?</p>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={closeModal}
          style={{ marginLeft: '1rem' }}
        >
          Cancel
        </Button>
      </Modal>
    </div>
  );
};

export default CouponList;
