// src/api/api.js
import axiosInstance from "./axiosInstance";

// Fetch data (GET request)
export const fetchDataFromApi = async (url) => {
  try {
    const { data } = await axiosInstance.get(url); // Use axiosInstance here
    return data;
  } catch (error) {
    console.error("Fetch Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Upload Image (POST with FormData)
export const uploadImage = async (url, formData) => {
  try {
    const { data } = await axiosInstance.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
    return data;
  } catch (error) {
    console.error("Image Upload Error:", error);
    throw error;
  }
};

// Post or Update Data
export const postData = async (url, data, method = "POST") => {
  try {
    const response = await axiosInstance({
      method,
      url,
      data
    });
    return response.data;
  } catch (error) {
    console.error("Post Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Edit Data (PUT request)
export const editData = async (url, updatedData) => {
  try {
    const { data } = await axiosInstance.put(url, updatedData);
    return data;
  } catch (error) {
    console.error("Edit Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Delete Data (DELETE request)
export const deleteData = async (url) => {
  try {
    const { data } = await axiosInstance.delete(url);
    return data;
  } catch (error) {
    console.error("Delete Data Error:", error);
    return error.response ? error.response.data : error;
  }
};

// Delete Images
export const deleteImages = async (url) => {
  try {
    const { data } = await axiosInstance.delete(url);
    return data;
  } catch (error) {
    console.error("Delete Image Error:", error);
    return error.response ? error.response.data : error;
  }
};
