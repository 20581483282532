import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { fetchDataFromApi } from "../../utils/api";
import {
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Avatar,
  Grid,
  Breadcrumbs,
  Button,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const OrderDetail = () => {
  const { id } = useParams(); // Get the order ID from the URL
  const [order, setOrder] = useState(null);

  useEffect(() => {
    fetchDataFromApi(`/api/orders/${id}`).then((res) => setOrder(res));
  }, [id]);

  if (!order) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatCurrency = (value) => {
    return !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : "0.00";
  };

  const calculateCouponDiscount = () => {
    const subtotal = order.products.reduce((acc, product) => acc + parseFloat(product.subTotal), 0);
    const totalAmount = parseFloat(order.amount);
    const discount = subtotal - totalAmount;
    const discountPercentage = subtotal > 0 ? ((discount / subtotal) * 100).toFixed(2) : "0.00";
    return { discount, discountPercentage };
  };

  const { discount, discountPercentage } = calculateCouponDiscount();

  const getPaymentMethod = (paymentId) => {
    if (paymentId.includes("WALLET")) return "Wallet";
    if (paymentId.includes("pay")) return "Razorpay";
    if (paymentId.includes("COD")) return "Cash on Delivery";
    return "Unknown";
  };

  return (
    <Box sx={{ padding: 5, marginTop: 7 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ marginBottom: 3, marginTop: 6 }}
      >
        <Link to="/" style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link to="/orders" style={{ color: 'inherit' }}>
          Orders
        </Link>
        <Typography color="textPrimary">Order Details</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: 4 }}>
        Order Details
      </Typography>
        <Box sx={{ backgroundColor: 'transparent ', padding: 3, borderRadius: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Order ID:</strong> {order._id}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Name:</strong> {order.name}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Phone Number:</strong> {order.phoneNumber}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Address:</strong> {order.address}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Pincode:</strong> {order.pincode}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Total Amount:</strong> ₹{formatCurrency(order.amount)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Payment ID:</strong> {order.paymentId} ({getPaymentMethod(order.paymentId)})
          </Typography>
          {discount > 0 && (
            <Typography variant="body1" sx={{ marginTop: 3 }}>
              <strong>Coupon Discount:</strong> ₹{formatCurrency(discount)} ({discountPercentage}%)
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Email:</strong> {order.email}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>User ID:</strong> {order.userid}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Status:</strong> {order.status}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Date:</strong> {new Date(order.date).toLocaleString()}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>Created Date:</strong> {new Date(order.createdDate).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
          </Box>

      <Typography variant="h5" gutterBottom sx={{ marginTop: 4, marginBottom: 3 }}>
        Products:
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Product ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Subtotal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar
                    variant="square"
                    src={product.image}
                    alt={product.productTitle}
                    sx={{ width: 56, height: 56 }}
                  />
                </TableCell>
                <TableCell>{product.productId}</TableCell>
                <TableCell>{product.productTitle}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>₹{formatCurrency(product.price)}</TableCell>
                <TableCell>₹{formatCurrency(product.subTotal)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderDetail;
