import React, { useEffect, useState } from 'react';
import { fetchDataFromApi, editData } from '../../utils/api'; // API functions
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-modal';
import TableComponent from '../../components/TableComponent/TableComponent';
import "./syle.css";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Define page and setPage
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('name');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ action: '', userId: '' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []); // Fetch users only once on component mount

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchDataFromApi(`/api/admin/user`);
      console.log(response);  // Check the structure of the response
      setUsers(response);     // Assuming the data is directly in response
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (action, userId) => {
    setModalContent({ action, userId });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirm = async () => {
    const { action, userId } = modalContent;
    try {
      if (action === 'block') {
        await editData(`/api/admin/user/block/${userId}`);
      } else if (action === 'unblock') {
        await editData(`/api/admin/user/unblock/${userId}`);
      }
      fetchUsers(); // Re-fetch users after action
    } catch (error) {
      console.error(`Error ${action}ing user:`, error);
    } finally {
      closeModal();
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  const filteredAndSortedUsers = users
    .filter((user) => !user.isAdmin && user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) // Exclude admins and filter by search term
    .sort((a, b) => a[sortField].localeCompare(b[sortField])); // Sort by selected field

  const paginatedUsers = filteredAndSortedUsers.slice((page - 1) * 4, page * 4); // Show only 4 users per page

  const columns = [
    { label: 'Username', field: 'name' },
    { label: 'Email', field: 'email' },
    { label: 'Status', field: 'isBlocked', render: (row) => (row.isBlocked ? 'Blocked' : 'Active') },
    { label: 'Actions', field: 'actions', render: (row) => (
      row.isBlocked ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal('unblock', row._id)}
        >
          Unblock
        </Button>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => openModal('block', row._id)}
        >
          Block
        </Button>
      )
    )}
  ];

  return (
    <div className="right-content w-100" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
        <h5 className="mb-0">User List</h5>
      </div>

      <div className="search-sort-controls p-4 mt-2">
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginRight: '1rem' }}
        />
        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={handleSortChange}
          variant="outlined"
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="email">Email</MenuItem>
        </TextField>
      </div>

      {loading ? (
        <div className="loading" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <div className="card p-4 mt-2" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {users.length === 0 ? (
            <p>No users available.</p>
          ) : (
            <>
              <TableComponent
                columns={columns}
                data={paginatedUsers}
                rowKey="_id"
                noDataMessage="No users found."
              />
              {/* Pagination Controls */}
              <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setPage(page + 1)}
                  disabled={page * 4 >= filteredAndSortedUsers.length}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Action"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            textAlign: 'center'
          }
        }}
      >
        <h2>Confirm Action</h2>
        <p>Are you sure you want to {modalContent.action} this user?</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UserList;
