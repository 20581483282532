import React, { useContext, useEffect, useState } from "react";
import DashboardBox from "./components/dashboardBox";
import { FaUserCircle } from "react-icons/fa";
import { MdShoppingBag, MdShoppingCart } from "react-icons/md";
import { GiStarsStack } from "react-icons/gi";
import Menu from "@mui/material/Menu";
import SearchBox from "../../components/SearchBox";
import MenuItem from "@mui/material/MenuItem";
import { IoIosTimer } from "react-icons/io";
import Button from "@mui/material/Button";
import { Chart } from "react-google-charts";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import { MyContext } from "../../App";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Rating from "@mui/material/Rating";
import { deleteData, fetchDataFromApi } from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const data = [
  ["Year", "Sales", "Expenses"],
  ["2013", 1000, 400],
  ["2014", 1170, 460],
  ["2015", 660, 1120],
  ["2016", 1030, 540],
];

export const options = {
  backgroundColor: "transparent",
  chartArea: { width: "50%", height: "50%" },
};

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBy, setshowBy] = useState(10);
  const [showBysetCatBy, setCatBy] = useState("");
  const [productList, setProductList] = useState([]);
  const [categoryVal, setcategoryVal] = useState("all");

  const [totalUsers, setTotalUsers] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [totalProductsReviews, setTotalProductsReviews] = useState();
  const [perPage, setPerPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState();

  const [salesData, setSalesData] = useState([]);
  const [view, setView] = useState("daily");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bestSellingCategories, setBestSellingCategories] = useState([]);
  const [bestSellingBrands, setBestSellingBrands] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const context = useContext(MyContext);
  const open = Boolean(anchorEl);
  const fetchBestSellingCategories = () => {
    fetchDataFromApi("/api/orders/get/categorySales")
      .then((res) => {
        console.log("Category Sales Response:", res.data); // Log the response
        setBestSellingCategories(res.data);
      })
      .catch((error) => console.error("Error fetching best-selling categories:", error));
  };
  
  const fetchBestSellingBrands = () => {
    fetchDataFromApi("/api/orders/get/brandSales")
      .then((res) => {
        console.log("Brand Sales Response:", res.data); // Log the response
        setBestSellingBrands(res.data);
      })
      .catch((error) => console.error("Error fetching best-selling brands:", error));
  };
  useEffect(() => {
    console.log("Best-Selling Categories Updated:", bestSellingCategories);
  }, [bestSellingCategories]);
  
  useEffect(() => {
    console.log("Best-Selling Brands Updated:", bestSellingBrands);
  }, [bestSellingBrands]);
  
  useEffect(() => {
    context.setisHideSidebarAndHeader(false);
    window.scrollTo(0, 0);
    context.setProgress(40);

    fetchDataFromApi(`/api/products?page=1&perPage=${perPage}`).then((res) => {
      setProductList(res);
      context.setProgress(100);
    });
    
    fetchDataFromApi("/api/admin/user/get/count").then((res) => setTotalUsers(res.userCount));
    fetchDataFromApi("/api/products/get/count").then((res) => setTotalProducts(res.productsCount));
    fetchDataFromApi("/api/productReviews/get/count").then((res) => setTotalProductsReviews(res.productsReviews));
   
    fetchBestSellingProducts();
    fetchSalesData();
    fetchBestSellingCategories();
    fetchBestSellingBrands();
    fetchPaymentMethods();
  }, []);
 

  const fetchBestSellingProducts = () => {
    fetchDataFromApi("/api/orders")
      .then((res) => {
        const productSales = {};
        const productDetailsPromises = [];
        res.forEach((order) => {
          if (["payment failed", "cancelled", "returned"].includes(order.status)) return;
          order.products.forEach((product) => {
            if (productSales[product.productId]) {
              productSales[product.productId].totalSales += product.quantity;
            } else {
              productSales[product.productId] = {
                productId: product.productId,
                productName: product.productTitle,
                totalSales: product.quantity,
              };
              productDetailsPromises.push(
                fetchDataFromApi(`/api/products/${product.productId}`).then((productDetails) => {
                  productSales[product.productId].category = productDetails.category.name;
                  productSales[product.productId].brand = productDetails.brand;
                })
              );
            }
          });
        });
        Promise.all(productDetailsPromises).then(() => {
          const sortedProducts = Object.values(productSales).sort((a, b) => b.totalSales - a.totalSales);
          setBestSellingProducts(sortedProducts.slice(0, 10));
        });
      })
      .catch((error) => console.error("Error fetching orders:", error));
  };

  const fetchSalesData = () => {
    setIsLoading(true);
    fetchDataFromApi("/api/orders/get/active")
      .then((orders) => {
        let totalSalesCount = 0;
        const processedData = orders.map((order) => {
          const subtotal = order.subtotal || order.products.reduce((sum, product) => sum + product.price * product.quantity, 0);
          const amount = order.amount;
          const discount = subtotal - amount;
          const utcDate = new Date(order.date);
          const dateString = utcDate.toISOString().split("T")[0];
          const timeString = utcDate.toISOString().split("T")[1].slice(0, 8);
          
          totalSalesCount += order.products.reduce((count, product) => count + product.quantity, 0);
          
          return { date: dateString, totalSales: amount, subtotal, discount };
        });
        processedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setSalesData(processedData);
        console.log("Total Sales Count:", totalSalesCount);
        setTotalOrders(totalSalesCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setIsLoading(false);
      });
  };

  const fetchPaymentMethods = () => {
    fetchDataFromApi("/api/orders/get/paymentMethods")
    .then((res) => {
      const formattedData = res.data.map((item) => ({
        paymentMethod: item.paymentMethod || "Unknown",
        count: item.count || 0,
      }));
      setPaymentMethods(formattedData);
    })
    .catch((error) => console.error("Error fetching payment methods:", error));
  
  };

  const handleViewChange = (event) => {
    setView(event.target.value);
    setStartDate("");
    setEndDate("");
  };

  const getFilteredData = () => {
    if (view === "daily" && startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return salesData.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    } else if (view === "weekly" || view === "monthly" || view === "yearly") {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return salesData.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }
    return salesData;
  };

  const filteredData = getFilteredData();

  const chartData = {
    labels: filteredData.map((entry) => entry.date),
    datasets: [
      {
        label: `Total Sales (${view})`,
        data: filteredData.map((entry) => entry.totalSales),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        tension: 0.2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: `Sales Report (${view})` },
    },
    scales: {
      x: { title: { display: true, text: "Date" } },
      y: { title: { display: true, text: "Sales (in Rs)" }, beginAtZero: true },
    },
  };

  const paymentMethodData = paymentMethods.map((method) => [method.paymentMethod, method.count]);
  const paymentMethodChartData = [["Payment Method", "Count"], ...paymentMethodData];

  const paymentMethodChartOptions = {
    title: "Payment Methods Distribution",
    pieHole: 0.4,
    is3D: false,
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="row dashboardBoxWrapperRow dashboard_Box dashboardBoxWrapperRowV2">
          <div className="col-md-12">
            <div className="dashboardBoxWrapper d-flex">
              <DashboardBox color={["#1da256", "#48d483"]} icon={<FaUserCircle />} grow={true} title="Total Users" count={totalUsers - 1} />
              <DashboardBox color={["#2c78e5", "#60aff5"]} icon={<MdShoppingBag />} title="Total Products" count={totalProducts} />
              <DashboardBox color={["#e1950e", "#f3cd29"]} icon={<GiStarsStack />} title="Total Reviews" count={totalProductsReviews} />
              <DashboardBox color={["#ff5733", "#ff8d47"]} icon={<MdShoppingCart />} title="Total Orders" count={totalOrders ?? "Loading..."} />
            </div>
          </div>

          <div className="col-md-4 pl-0 d-none">
            <div className="box graphBox">
              <div className="d-flex align-items-center w-100 bottomEle">
                <h6 className="text-white mb-0 mt-0">Total Sales</h6>
              </div>

              <Chart chartType=" " width="100%" height="170px" data={data} options={options} />
            </div>
          </div>
        </div>

<div className="d-flex justify-content-between">
  {/* Payment Methods */}
  <div className="card shadow border-0 p-4 mt-4" style={{ width: "48%" }}>
    <h4 className="text-primary text-left p-3">Payment Methods Distribution</h4>
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <Chart
        chartType="PieChart"
        width="100%"
        height="350px"
        data={paymentMethodChartData}
        options={paymentMethodChartOptions}
      />
    </div>
  </div>

  <div className="card shadow border-0 p-4 mt-4" style={{ width: "48%" }}>
    <h4 className="text-primary text-left p-3">Most Selling Categories</h4>
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <Chart
        chartType="BarChart"
        width="100%"
        height="350px"
        data={[
          ["Category", "Total Sales"],
          ...bestSellingCategories.map((category) => [category.categoryName, category.totalSales]),
        ]}
        options={{
          title: "Most Selling Categories",
          chartArea: { width: "60%" },
          hAxis: {
            title: "Total Sales",
            minValue: 0,
          },
          vAxis: {
            title: "Category",
          },
        }}
      />
    </div>
  </div>
</div>


        <div className="card shadow border-0 p-3 mt-4">
          <h5 className="text-primary text-center p-2">Sales Graph</h5>
          <FormControl variant="outlined" sx={{ minWidth: 120, marginBottom: 3 }}>
            <InputLabel>View</InputLabel>
            <Select value={view} onChange={handleViewChange} label="View">
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          {isLoading ? <CircularProgress /> : <Line data={chartData} options={chartOptions} />}
        </div>

        <div className="card shadow border-0 p-3 mt-4">
          <h5 className="text-primary text-center p-2">Top 10 Best-Selling  Products </h5>
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Product Name</th>
                <th scope="col">Category</th>
                <th scope="col">Brand</th>
                <th scope="col">Total Sales</th>
              </tr>
            </thead>
            <tbody>
              {bestSellingProducts.length > 0 ? (
                bestSellingProducts.map((product, index) => (
                  <tr key={product.productId}>
                    <td>{index + 1}</td>
                    <td>{product.productName}</td>
                    <td>{product.category}</td>
                    <td>{product.brand}</td>
                    <td>{product.totalSales}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center text-muted">
                    No Best-Selling Products Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
             {/* Best-Selling Categories */}
      <div className="card shadow border-0 p-3 mt-4">
        <h5 className="text-primary text-center p-2">Top 10 Best-Selling Categories</h5>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Rank</th>
              <th scope="col">Category</th>
              <th scope="col">Total Sales</th>
            </tr>
          </thead>
          <tbody>
  {bestSellingCategories.length > 0 ? (
    bestSellingCategories
      .sort((a, b) => b.totalSales - a.totalSales)
      .map((category, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{category.categoryName}</td>
          <td>{category.totalSales}</td>
        </tr>
      ))
  ) : (
    <tr>
      <td colSpan="3" className="text-center text-muted">
        No Best-Selling Categories Available
      </td>
    </tr>
  )}
</tbody>

        </table>
      </div>

      {/* Best-Selling Brands */}
      <div className="card shadow border-0 p-3 mt-4">
        <h5 className="text-primary text-center p-2">Top 10 Best-Selling Brands</h5>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Rank</th>
              <th scope="col">Brand</th>
              <th scope="col">Total Sales</th>
            </tr>
          </thead>
          <tbody>
  {bestSellingBrands.length > 0 ? (
    bestSellingBrands.map((brand, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{brand.brandName}</td>
        <td>{brand.totalSales}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="3" className="text-center text-muted">
        No Best-Selling Brands Available
      </td>
    </tr>
  )}
</tbody>


        </table>
      </div>

     
        </div>
      </div>
    </>
  );
};

export default Dashboard;
