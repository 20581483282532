import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const ResponsiveTableContainer = styled(TableContainer)({
  overflowX: "auto",
});

const StyledTableCell = styled(TableCell)({
  backgroundColor: "#007bff",
  color: "#fff",
  fontWeight: "bold",
});

const StyledButtonGreen = styled(Button)({
  minWidth: "30px",
  height: "30px",
  padding: "0",
  margin: "0 5px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#28a745",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#218838",
  },
});

const StyledButtonRed = styled(Button)({
  minWidth: "30px",
  height: "30px",
  padding: "0",
  margin: "0 5px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#dc3545",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
});

const TableComponent = ({ columns, data, rowKey, noDataMessage = "No data available" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ResponsiveTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <StyledTableCell key={index} align={col.align || "left"}>
                <Typography variant="whit" fontWeight="bold">
                  {col.label}
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={row[rowKey] || rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell key={colIndex} align={col.align || "left"}>
                    {col.render ? col.render(row, rowIndex) : row[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                {noDataMessage}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isMobile && (
        <Typography variant="caption" align="center" display="block" style={{ padding: "10px" }}>
          Swipe left/right to see more columns
        </Typography>
      )}
    </ResponsiveTableContainer>
  );
};

export default TableComponent;
