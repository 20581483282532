import React, { useState, useContext, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { MyContext } from "../../App"; // Your context file
import { postData, fetchDataFromApi } from "../../utils/api"; // API post and fetch functions
import { useNavigate, useParams } from "react-router-dom";

// Breadcrumb styling
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const AddCoupon = () => {
  const [formFields, setFormFields] = useState({
    code: "",
    discount: "",
    expiryDate: "",
    maxDiscountPrice: "", // Added max discount price field
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const { id } = useParams(); // Coupon ID from the URL
  const context = useContext(MyContext);
  const isEdit = Boolean(id); // Check if editing based on presence of ID

  // Fetch coupon details if editing
  useEffect(() => {
    if (isEdit) {
      fetchCouponDetails();
    }
  }, [isEdit]);

  // Fetch coupon details if editing
  const fetchCouponDetails = async () => {
    try {
      const response = await fetchDataFromApi(`/api/coupons/${id}`);
      if (response.success) {
        // Pre-fill form with coupon data
        setFormFields({
          code: response.coupon.code,
          discount: response.coupon.discount,
          expiryDate: new Date(response.coupon.expiryDate)
            .toISOString()
            .substr(0, 10), // Convert date to yyyy-mm-dd format
          maxDiscountPrice: response.coupon.maxDiscountPrice, // Set max discount price
        });
      } else {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Failed to fetch coupon details.",
        });
      }
    } catch (error) {
      console.error("Error fetching coupon:", error);
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Error fetching coupon details.",
      });
    }
  };

  // Function to handle input changes
  const changeInput = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };

  // Function to check if the expiry date is in the past
  const isDateInPast = (selectedDate) => {
    const today = new Date();
    const expiry = new Date(selectedDate);
    return expiry < today.setHours(0, 0, 0, 0); // Compare without considering the time
  };

  // Function to submit the coupon form
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the expiry date is in the past
    if (isDateInPast(formFields.expiryDate)) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "The expiry date cannot be in the past!",
      });
      return;
    }

    // Check if the discount percentage is between 1 and 100
    if (formFields.discount < 1 || formFields.discount > 100) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "The discount percentage must be between 1 and 100!",
      });
      return;
    }

    setIsLoading(true);
    

    // If editing, send a PUT request; otherwise, send a POST request
    const method = isEdit ? "PUT" : "POST";
    const url = isEdit ? `/api/coupons/${id}` : `/api/coupons/create`;

    postData(url, formFields, method)
      .then((res) => {
        setIsLoading(false);
        if (res && res.success) {
          context.setAlertBox({
            open: true,
            error: false,
            msg: `Coupon ${isEdit ? "Updated" : "Created"} Successfully!`,
          });
          history("/coupons");
        } else {
          context.setAlertBox({
            open: true,
            error: true,
            msg: res?.message || `Failed to ${isEdit ? "update" : "create"} coupon.`,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        context.setAlertBox({
          open: true,
          error: true,
          msg: `An error occurred while ${isEdit ? "updating" : "creating"} the coupon.`,
        });
        console.error(`Error ${isEdit ? "updating" : "creating"} coupon:`, error);
      });
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
          <h5 className="mb-0">{isEdit ? "Edit Coupon" : "Add Coupon"}</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="Dashboard"
              icon={<HomeIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label="Coupons"
              href="#"
              deleteIcon={<ExpandMoreIcon />}
            />
            <StyledBreadcrumb
              label={isEdit ? "Edit Coupon" : "Add Coupon"}
              deleteIcon={<ExpandMoreIcon />}
            />
          </Breadcrumbs>
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-9">
              <div className="card p-4 mt-0">
                <div className="form-group">
                  <h6>Coupon Code</h6>
                  <input
                    type="text"
                    name="code"
                    value={formFields.code}
                    onChange={changeInput}
                    className="form-control"
                    placeholder="Enter coupon code"
                    required
                  />
                </div>

                <div className="form-group">
                  <h6>Discount Percentage (%)</h6>
                  <input
                    type="number"
                    name="discount"
                    value={formFields.discount}
                    onChange={changeInput}
                    className="form-control"
                    placeholder="Enter discount percentage"
                    required
                  />
                </div>
                  
                <div className="form-group">
                  <h6>Expiry Date</h6>
                  <input
                    type="date"
                    name="expiryDate"
                    value={formFields.expiryDate}
                    onChange={changeInput}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <h6>Maximum Discount Price</h6>
                  <input
                    type="number"
                    name="maxDiscountPrice"
                    value={formFields.maxDiscountPrice}
                    onChange={changeInput}
                    className="form-control"
                    placeholder="Enter maximum discount price"
                    required
                  />
                </div>

                <Button
                  type="submit"
                  className="btn-blue btn-lg btn-big w-100"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" className="loader" />
                  ) : (
                    isEdit ? "Update Coupon" : "Create Coupon"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCoupon;
