import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MyContext } from "../../App";
import { Link } from "react-router-dom";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { deleteData, fetchDataFromApi } from "../../utils/api";
import Pagination from "@mui/material/Pagination";

// Breadcrumb styling
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const BannersList = () => {
  const [slideList, setSlideList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const context = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    context.setProgress(20);

    fetchDataFromApi("/api/banners")
      .then((res) => {
        if (Array.isArray(res)) {
          setSlideList(res);
          setTotalPages(Math.ceil(res.length / itemsPerPage));
        } else {
          setSlideList([]);
        }
        setIsLoading(false);
        context.setProgress(100);
      })
      .catch((err) => {
        console.error("Error fetching banners:", err);
        setIsLoading(false);
        context.setProgress(100);
      });
  }, [page]);

  const deleteSlide = (id) => {
    context.setProgress(30);
    deleteData(`/api/banners/${id}`).then(() => {
      fetchDataFromApi("/api/banners").then((res) => {
        setSlideList(Array.isArray(res) ? res : []);
        context.setProgress(100);
        context.setAlertBox({
          open: true,
          error: false,
          msg: "Banner Deleted!",
        });
      });
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedSlides = slideList.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4 align-items-center">
          <h5 className="mb-0">Banner Slide List</h5>

          <div className="ml-auto d-flex align-items-center">
            <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
              <StyledBreadcrumb
                component="a"
                href="#"
                label="Dashboard"
                icon={<HomeIcon fontSize="small" />}
              />
              <StyledBreadcrumb
                label="Banners"
                deleteIcon={<ExpandMoreIcon />}
              />
            </Breadcrumbs>

            <Link to="/banners/add">
              <Button className="btn-blue ml-3 pl-3 pr-3">Add Home Banner</Button>
            </Link>
          </div>
        </div>

        <div className="card shadow border-0 p-3 mt-4">
          <div className="table-responsive mt-3">
            {isLoading ? (
              <div>Loading banners...</div>
            ) : (
              <table className="table table-bordered table-striped v-align">
                <thead className="thead-dark">
                  <tr>
                    <th style={{ width: "200px" }}>IMAGE</th>
                    <th>ACTION</th>
                  </tr>
                </thead>

                <tbody>
                  {paginatedSlides.length > 0 ? (
                    paginatedSlides.map((item, index) => (
                      <tr key={item.id || index}>
                        <td>
                          <div className="d-flex align-items-center" style={{ width: "200px" }}>
                            <div className="imgWrapper" style={{ width: "200px", flex: "0 0 200px" }}>
                              <div className="img card shadow m-0">
                                <LazyLoadImage
                                  alt={"image"}
                                  effect="blur"
                                  className="w-100"
                                  src={item.images?.[0] || ""}
                                />
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="actions d-flex align-items-center">
                            <Link to={`/banners/edit/${item.id}`}>
                              <Button className="success" color="success">
                                <FaPencilAlt />
                              </Button>
                            </Link>

                            <Button
                              className="error"
                              color="error"
                              onClick={() => deleteSlide(item.id)}
                            >
                              <MdDelete />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No banners available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BannersList;
