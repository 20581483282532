// utils/cropImage.js
export const getCroppedImg = async (imageSrc, crop, fileType = 'image/jpeg', quality = 1) => {
  try {
    const image = await loadImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas size to the size of the image for free crop selection
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height
    );

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    croppedCanvas.width = crop.width;
    croppedCanvas.height = crop.height;

    croppedCtx.drawImage(
      canvas,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const blob = await new Promise((resolve) => croppedCanvas.toBlob(resolve, fileType, quality));
    if (!blob) {
      throw new Error('Crop failed.');
    }
    return URL.createObjectURL(blob);
  } catch (err) {
    throw err;
  }
};

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
  });
};